'use client'

import React, { type ReactNode, useEffect, useRef, useState } from 'react'

import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import debounce from 'lodash/debounce'
import { styled } from 'styled-components'

import { designColors, shadows } from '@b-stock/bstock-react/theme'

interface CarouselProps {
  children: ReactNode[]
  minItemsToShowButtons?: number
  disabled?: boolean
}

export const CarouselContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
`

const CarouselItemContainer = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  margin: 0 1.375rem;
  padding: 0.125rem 0;
`

export const CarouselItemWrapper = styled.div`
  display: flex;
`

const CarouselItem = styled.div`
  flex-shrink: 0;
  scroll-snap-align: start;
  margin-right: 1.5rem;
`

const CarouselButton = styled.button.attrs({ type: 'button' })<{
  $side: 'left' | 'right'
  $disabled: boolean
}>`
  appearance: none;
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 50%;
  font-size: 1rem;
  width: 2.75rem;
  max-width: 2.75rem;
  height: 2.75rem;
  max-height: 2.75rem;
  background-color: ${designColors.neutral.white};
  color: ${({ $disabled }) =>
    $disabled ? designColors.neutral.darkGray : designColors.neutral.black};
  z-index: 30;
  cursor: pointer;
  position: absolute;
  ${({ $side }) => $side}: 0;
  ${shadows.multipleCards}
`
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  width: 1rem;
  max-width: 1rem;
  height: 1rem;
  max-height: 1rem;
`
/**
 * Carousel component. Creates a carousel of items that can be scrolled through
 * using left and right buttons. Will automatically scroll an appropriate
 * distance based on the width of the carousel.
 *
 * Current limitations:
 * - Assumes all items have the same width (based on the first item's width)
 * - Uses unorthodox method of introspecting child elements to determine the
 *   width of the carousel items. This is not ideal, but it works for now.
 */
const Carousel = ({
  children,
  minItemsToShowButtons,
  disabled = false,
}: CarouselProps) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [itemWidth, setItemWidth] = useState<number>(0)
  const [containerWidth, setContainerWidth] = useState<number>(0)

  const carouselRef = useRef<HTMLDivElement>(null)
  const itemRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const updateDimensions = debounce(() => {
      if (itemRef.current && carouselRef.current) {
        const item = itemRef.current.firstElementChild as HTMLElement
        const newContainerWidth = carouselRef.current.clientWidth
        const newItemWidth =
          item.clientWidth + parseFloat(getComputedStyle(item).marginRight)

        setContainerWidth(newContainerWidth)
        setItemWidth(newItemWidth)
      }
    }, 200)

    updateDimensions()

    window.addEventListener('resize', updateDimensions)

    return () => window.removeEventListener('resize', updateDimensions)
  }, [children])

  const visibleItems = Math.floor(containerWidth / itemWidth)
  const isLeftBtnDisabled = disabled || currentIndex === 0
  const isRightBtnDisabled =
    disabled || currentIndex >= children.length - visibleItems

  const showButtons =
    minItemsToShowButtons === undefined ||
    children.length >= minItemsToShowButtons
  const onRightBtnClick = () => {
    if (carouselRef.current) {
      const nextIndex = Math.min(
        currentIndex + Math.max(1, visibleItems),
        children.length - visibleItems
      )
      scrollToIndex(nextIndex)
      setCurrentIndex(nextIndex)
    }
  }

  const onLeftBtnClick = () => {
    if (carouselRef.current) {
      const visibleItems = Math.floor(containerWidth / itemWidth)
      const prevIndex = Math.max(currentIndex - Math.max(1, visibleItems), 0)
      scrollToIndex(prevIndex)
      setCurrentIndex(prevIndex)
    }
  }

  const scrollToIndex = (index: number) => {
    if (carouselRef.current) {
      const offsetLeft = index * itemWidth
      carouselRef.current.scrollTo({ left: offsetLeft, behavior: 'smooth' })
    }
  }

  return (
    <CarouselContainer>
      {showButtons && (
        <CarouselButton
          $side="left"
          $disabled={isLeftBtnDisabled}
          disabled={isLeftBtnDisabled}
          onClick={onLeftBtnClick}
        >
          <StyledFontAwesomeIcon icon={faAngleLeft} />
        </CarouselButton>
      )}
      <CarouselItemContainer ref={carouselRef}>
        <CarouselItemWrapper ref={itemRef}>
          {children.map((child) => {
            if (React.isValidElement(child)) {
              return <CarouselItem key={child.key}>{child}</CarouselItem>
            }
            return null
          })}
        </CarouselItemWrapper>
      </CarouselItemContainer>
      {showButtons && (
        <CarouselButton
          $side="right"
          $disabled={isRightBtnDisabled}
          disabled={isRightBtnDisabled}
          onClick={onRightBtnClick}
        >
          <StyledFontAwesomeIcon icon={faAngleRight} />
        </CarouselButton>
      )}
    </CarouselContainer>
  )
}

export default Carousel
