'use client'

import { useRef } from 'react'

import { useAnalytics } from '@helpers/telemetry/SegmentAnalytics'

export default function TrackPageView({
  pageName,
  ...additionalData
}: {
  pageName: string
  additionalData?: Record<string, string | number | boolean | undefined>
}) {
  const analytics = useAnalytics()
  const hasTracked = useRef(false)

  // Use hasTracked ref to ensure the page view is only tracked once, even if
  // the component is re-rendered.
  // eslint-disable-next-line react-compiler/react-compiler
  if (typeof window !== 'undefined' && !hasTracked.current) {
    // TODO: Add awareness of the user's role (buyer, seller, etc.) after auth
    // is implemented.
    analytics.pageViewed(pageName, 'home_portal', 'buyer', {
      url: document.URL,
      referrer: document.referrer,
      ...additionalData,
    })
    // eslint-disable-next-line react-compiler/react-compiler
    hasTracked.current = true
  }

  return null
}
