export async function fetchData<T>(url: string): Promise<T> {
  const response = await fetch(url, {
    next: {
      revalidate: 4,
    },
  })
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`)
  }
  return response.json() as Promise<T>
}

export const API_ENDPOINTS = {
  amazonBlue:
    'https://ai-search.bstock.com/live-auctions?query=%20&offset=0&limit=50&filter=siteAbb%3AANY%28%22amz%22%29&filter=sellerRegion%3AANY%28%22Hebron%22%29',
  amazonUS:
    'https://ai-search.bstock.com/live-auctions?query=%20&offset=0&limit=50&filter=siteAbb%3AANY%28%22amz%22%29',
  fewBidsEndingSoon:
    'https://ai-search.bstock.com/live-auctions?query=%20&offset=0&limit=50&filter=low_bids%20%3D%20"true"&order_by=endTime',
  target:
    'https://ai-search.bstock.com/live-auctions?query=%20&offset=0&limit=50&filter=siteAbb%3AANY%28%22tgt%22%29',
  walmart:
    'https://ai-search.bstock.com/live-auctions?query=%20&offset=0&limit=50&filter=siteAbb%3AANY%28%22wal%22%29',
}
