//For Safari!
// This function is used to parse the date string from the auction data

export function parseCustomDate(dateString: string): Date {
  // Replace the space between the date and time with a "T" to make it ISO 8601 compliant
  const isoDateString = dateString.replace(' ', 'T')

  // Try to parse the date string using the Date constructor
  let date = new Date(isoDateString)

  if (isNaN(date.getTime())) {
    const [datePart, timePart] = dateString.split(' ')
    const [year, month, day] = datePart.split('-').map(Number)
    const [hours, minutes, seconds] = timePart.split(':').map(Number)

    // Create a new Date object
    date = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds))
  }

  if (isNaN(date.getTime())) {
    console.error('Invalid date string:', dateString)
    return new Date()
  }

  return date
}

export const calcIsPast = (date: Date): boolean => {
  const now = new Date()
  return date <= now
}

export const formatDate = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }

  return new Intl.DateTimeFormat('en-US', options).format(date)
}
